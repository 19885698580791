import React, { useEffect, useState } from "react";
import { Table } from "@themesberg/react-bootstrap";
import { FormatDate, FormatDateTime } from "../../../components/dateformat";
import ViewImage from "./HandleImage";
import { axiosInstance } from "../../../axiosSetup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEdit,
  faTrashAlt,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import {
  contactStatusList,
  contactTypesList,
  documentTypesList,
} from "../../../data/sds_metadata";
import { StatusUpdate } from "../GridCellComponents";
import axios from "axios";
import { updateSDSdocAPIerp } from "../../../api/SdsAPI";
import PDFViewer1 from "./PdfViewer1";
import { Button as ButtonMui } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInTab from "./OpenInTab";

const InfoTable = (props) => {
  let { detail, generalMetadata, refreshDetails } = props;
  const [format, setFormat] = useState(null);
  const [preview, setPreview] = useState(null);
  const [mode, setMode] = useState("view");
  const [output, setOutput] = useState(detail);
  // console.log(`${process.env.REACT_APP_MEDIA_URL}${detail.file}`);

  const fetchFile = () => {
    const mediaUrl = `${localStorage.getItem("base_url")}${detail.file}`;
    axios
      .get(mediaUrl, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `token ${localStorage.getItem(
            "api_key"
          )}:${localStorage.getItem("api_secret")}`,
          //   accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectURL = URL.createObjectURL(blob);
        setPreview(objectURL);
        // Now, you can use objectURL for your purposes, e.g., displaying the PDF or using it in an <a> tag.
      })
      .catch((error) => {
        console.error("Error fetching or creating object URL:", error);
      });
  };

  const getFormat = () => {
    // console.log(detail);
    if (detail.image) {
      setFormat("image");
    } else if (detail.file) {
      const patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      const fileExtension = detail.file.match(patternFileExtension);
      setFormat(fileExtension[1]);

      // console.log(fileExtension)
      // fetchFile();
      // v1
      // URL.createObjectURL(`${localStorage.getItem("base_url")}${detail.file}`)
      const baseUrl = localStorage.getItem("base_url"); // Fetch the base URL
      const previewUrl = `${baseUrl}${detail.file}`; // Concatenate it with `detail.file`
      setPreview(previewUrl);
      // console.log(fileExtension); // Log the correctly formatted URL
    }
  };
  useEffect(() => {
    getFormat();
    setOutput(detail);
  }, [detail]);

  const changeMode = () => {
    // console.log(output);
    if (mode === "view") {
      setMode("edit");
    } else if (mode === "edit") {
      setOutput(detail);
      setMode("view");
    } else setMode("view");
  };
  const handleChange = (e) => {
    const targetNameArray = e.target.name.split(",");
    if (targetNameArray.length > 1) {
      setOutput({
        ...output,
        misc_data: {
          ...output.misc_data,
          [targetNameArray[1]]: e.target.value,
        },
      });
    } else {
      setOutput({ ...output, [targetNameArray[0]]: e.target.value });
    }
  };
  const handleSubmit = async () => {
    try {
      const res = await updateSDSdocAPIerp(output);
      // console.log(res);
      if (res.status == 200) {
        refreshDetails(output);
      }
    } catch (error) {
      console.log(error)
    }

    // axiosInstance
    //   .patch(`sds/${output.id}/`, {
    //     document_type: output.document_type,
    //     misc_data: output.misc_data,
    //   })
    //   .then((res) => {
    //     const data = res.data;
    //     refreshDetails(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    setMode("view");
  };

  return (
    <>
      {format === "image" && <ViewImage image={detail.image} />}
      {format === null && (
        <object
          // width="100%"
          height="600"
          className="card-img-top"
          data={`${localStorage.getItem("base_url")}${detail.file}`}
          type="application/pdf"
        >
          <p>
            <a
              href={`${localStorage.getItem("base_url")}${detail.file}`}
              target="__blank"
            >
              Click to download File
            </a>
          </p>
        </object>
      )}
      {/* {format === "pdf" && preview && (
        <object
          // width="100%"
          height="600"
          className="card-img-top"
          data={preview}
          type="application/pdf"
        >
          <p>
            Your browser does not support PDFs. You can download the PDF file
            <a href={preview} target="_blank" rel="noopener noreferrer">here</a>.
          </p>
        </object>
      )} */}

      {format?.toLowerCase() === "pdf" && preview && (
        <PDFViewer1
          previewP={preview}
          format="pdf"
        />
      )}

      {format?.toLowerCase() === "pdf" && preview && (
        <ButtonMui
          variant="outlined"
          style={{
            marginLeft: "15px",
            marginTop: "15px",
            backgroundColor: "#fff",
            color: "#050092",
            width: "auto",
            height: "auto"
          }}
          startIcon={<OpenInNewIcon />}
          component="a"
          href={preview}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open
        </ButtonMui>

      )}

      <div className="d-flex justify-content-end">
        {mode === "view" && (
          <Button
            size="sm"
            variant="outline-dark"
            className="mx-2 mt-2"
            onClick={changeMode}
          >
            <FontAwesomeIcon icon={faEdit} className="me-1" />
          </Button>
        )}
        {mode === "edit" && (
          <>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={changeMode}
              className="mx-2 my-2"
            >
              <FontAwesomeIcon icon={faWindowClose} className="me-1 " />
              Cancel
            </Button>
            <Button
              size="sm"
              variant="outline-tertiary"
              onClick={handleSubmit}
              className="mx-2 my-2"
            >
              <FontAwesomeIcon icon={faCheckSquare} className="me-1" />
              Save
            </Button>
          </>
        )}
      </div>

      <Table responsive className="align-items-center table-flush">
        <tbody>
          {generalMetadata &&
            generalMetadata.map((infoKey, index) => {
              if (infoKey.type === "char") {
                const value = output[infoKey.loc[0]]
                  ? infoKey.loc.reduce((a, i) => a[i], output)
                  : "-";
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    {mode === "view" && <td className="small">{value}</td>}
                    {mode === "edit" && infoKey.editable === "true" && (
                      <td>
                        <Form.Control
                          name={infoKey.loc}
                          defaultValue={value}
                          onChange={handleChange}
                        ></Form.Control>
                      </td>
                    )}
                    {mode === "edit" && infoKey.editable !== "true" && (
                      <td className="small">{value}</td>
                    )}
                  </tr>
                );
              } else if (infoKey.type === "text") {
                const value = output[infoKey.loc[0]]
                  ? infoKey.loc.reduce((a, i) => a[i], output)
                  : "-";
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    {mode === "view" && <td className="small">{value}</td>}
                    {mode === "edit" && infoKey.editable === "true" && (
                      <td>
                        <Form.Control
                          name={infoKey.loc}
                          defaultValue={value}
                          onChange={handleChange}
                          as="textarea"
                          rows={5}
                        ></Form.Control>
                      </td>
                    )}
                    {mode === "edit" && infoKey.editable !== "true" && (
                      <td className="small">{value}</td>
                    )}
                  </tr>
                );
              } else if (infoKey.type === "status") {
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    <td className="small">
                      <StatusUpdate
                        status={infoKey.loc.reduce((a, i) => a[i], output)}
                      />
                    </td>
                  </tr>
                );
              } else if (infoKey.type === "datetime") {
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    <td className="small">
                      {output[infoKey.loc[0]]
                        ? FormatDateTime(
                          infoKey.loc.reduce((a, i) => a[i], output)
                        )
                        : "-"}
                    </td>
                  </tr>
                );
              } else if (infoKey.type === "date") {
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    <td className="small">
                      {output[infoKey.loc[0]]
                        ? FormatDate(infoKey.loc.reduce((a, i) => a[i], output))
                        : "-"}
                    </td>
                  </tr>
                );
              } else if (infoKey.type === "select") {
                const value = output[infoKey.loc[0]]
                  ? infoKey.loc.reduce((a, i) => a[i], output)
                  : "-";
                return (
                  <tr key={`info-general-${index}`}>
                    <th scope="row" className="font-weight-bold text-sm small">
                      {infoKey.name}
                    </th>
                    {mode === "view" && <td className="small">{value}</td>}
                    {mode === "edit" && infoKey.editable === "true" && (
                      <td>
                        {/* <Form.Control
                          name={infoKey.loc}
                          defaultValue={value}
                          onChange={handleChange}
                        ></Form.Control> */}

                        <Form.Select
                          aria-label="Default select example"
                          name={infoKey.loc}
                          value={value}
                          onChange={handleChange}
                        >
                          {infoKey.loc.includes("document_type") &&
                            documentTypesList.map((documentType, index) => {
                              return (
                                <option
                                  key={`document_type_${index}`}
                                  value={documentType}
                                >
                                  {documentType}
                                </option>
                              );
                            })}
                          {infoKey.loc.includes("contact_type") &&
                            contactTypesList.map((contactType, index) => {
                              return (
                                <option
                                  key={`contact_type${index}`}
                                  value={contactType}
                                >
                                  {contactType}
                                </option>
                              );
                            })}
                          {infoKey.loc.includes("contact_status") &&
                            contactStatusList.map((contactStatus, index) => {
                              return (
                                <option
                                  key={`contact_status_${index}`}
                                  value={contactStatus}
                                >
                                  {contactStatus}
                                </option>
                              );
                            })}
                          {/* <option>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option> */}
                        </Form.Select>
                      </td>
                    )}
                    {mode === "edit" && infoKey.editable !== "true" && (
                      <td className="small">{value}</td>
                    )}
                  </tr>
                );
              } else {
                return <tr key={`info-general-${index}`}></tr>;
              }
            })}
        </tbody>
      </Table>
      {/* <span className="d-flex justify-content-end">
        {mode === "view" && (
          <Button
            size="sm"
            variant="outline-dark"
            className="mx-2 my-2"
            onClick={changeMode}
          >
            <FontAwesomeIcon icon={faEdit} className="me-1" />
          </Button>
        )}
        {mode === "edit" && (
          <>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={changeMode}
              className="mx-2 my-2"
            >
              <FontAwesomeIcon icon={faWindowClose} className="me-1 " />
              Cancel
            </Button>
            <Button
              size="sm"
              variant="outline-tertiary"
              onClick={handleSubmit}
              className="mx-2 my-2"
            >
              <FontAwesomeIcon icon={faCheckSquare} className="me-1" />
              Save
            </Button>
          </>
        )}
      </span> */}
    </>
  );
};

export default InfoTable;
