import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const MenuAppBar = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, labels.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const labels = ["Label 1", "Label 2", "Label 3"]; // Add your labels here

  useEffect(() => {
    const autoMoveToNextLabel = () => {
      if (activeStep === labels.length - 1) {
        // If at the last label, reset to Label 1
        setActiveStep(0);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    };

    const timeoutId = setTimeout(autoMoveToNextLabel, 5000);

    return () => clearTimeout(timeoutId);
  }, [activeStep, labels.length]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ borderRadius: "5px" , maxWidth:"100%" }}>
        <Toolbar style={{ height: "130px", justifyContent: "space-between" }}>
          {activeStep > 0 && (
            <KeyboardArrowLeft
              onClick={handleBack}
              style={{ color: "black", fontSize: "3rem" }}
            />
          )}
          <div
            style={{
              margin: "auto",
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            {labels[activeStep]}
          </div>

          {activeStep < labels.length - 1 && (
            <KeyboardArrowRight
              onClick={handleNext}
              style={{ color: "black", fontSize: "3rem" }}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;
