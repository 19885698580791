import { useState, useEffect } from 'react';
import axios from 'axios';

const PdfViewer1 = ({ previewP, format }) => {
    // const [preview, setPreview] = useState(null);
    // console.log(previewP)
    // const fetchAndRenderPDF = async () => {
    //     // try {
    //         // Use the proxy path for the specific PDF file
    //         const response = await axios.get(previewP, {
    //             responseType: 'arraybuffer',
    //         });
    //         const blob = new Blob([response.data], { type: 'application/pdf' });
    //         const url = URL.createObjectURL(blob);
    //         setPreview(url);
    //         console.log(response);
    //         console.log(url);

    // } catch (error) {
    //     console.error('Error fetching the file:', error);
    // }

    // };

    // useEffect(() => {
    //     if (format === 'pdf') {
    //         fetchAndRenderPDF();
    //     }

    //     return () => {
    //         if (preview) {
    //             URL.revokeObjectURL(preview);
    //         }
    //     };
    // }, [format]);

    return (
        <div>
            {/* {preview ? (
                <object
                    width="100%"
                    height="600"
                    className="card-img-top"
                    data={preview}
                    type="application/pdf"
                >
                    <p>
                        You can download the File
                        <a href={preview} target="_blank" rel="noopener noreferrer">here</a>.
                    </p>
                </object>
            ) : (
                <p>...</p>
            )} */}
            <div style={{ width: "100%", height: "100vh" }}>
                <iframe
                    src={previewP}
                    style={{ width: "100%", height: "100%" }}
                    title="PDF Viewer"
                ></iframe>
            </div>
        </div>
    );
};

export default PdfViewer1;
