import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const OpenInTab = ({ previewP }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <Card
                sx={{
                    minWidth: "275px",
                    boxShadow: 3,
                    textAlign: "center", // Center-aligns text content
                    padding: "1px",
                }}
            >
                <CardContent>
                    <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
                        Document <PictureAsPdfIcon fontSize="medium" />
                    </Typography>
                    <Typography variant="h3" component="div" sx={{ fontWeight: "bold", mt: 1 }}>
                        PDF
                    </Typography>
                   
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#050092",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#fff",
                                color: "#050092",
                            },
                        }}
                        startIcon={<OpenInNewIcon />}
                        component="a"
                        href={previewP}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Details
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default OpenInTab;
